import DefaultTenant from './components/TenantSpecific/Default.vue'
import Login from './components/Security/Login.vue'

const Dashboard =() =>import(/* webpackChunkName: "dashboard" */ './components/Dashboard/Dashboard.vue')

const ReportMaster  =() =>import(/* webpackChunkName: "reports" */ './components/Analysis/ReportMaster.vue')
const FundFactsheet =() =>import(/* webpackChunkName: "fundFactsheet" */ './components/Analysis/FundFactsheet.vue')

const RealPortfolio  =() =>import(/* webpackChunkName: "realPortfolios" */  './components/Portfolios/RealPortfolio.vue')
const ModelPortfolio  =() =>import(/* webpackChunkName: "modelPortfolios" */  './components/Portfolios/ModelPortfolio.vue')
const ModelPortfolioOptimisation  =() =>import(/* webpackChunkName: "portfolios" */  './components/Portfolios/ModelPortfolioOptimisation.vue')
const RPFactSheet  =() =>import(/* webpackChunkName: "portfolios" */  './components/Portfolios/RPFactSheet.vue')
const RPReport  =() =>import(/* webpackChunkName: "portfolios" */  './components/Portfolios/RPReport.vue')

const UserSettings =() =>import(/* webpackChunkName: "userSettings" */ './components/Admin/UserSettings.vue')
const BannerAdmin =() =>import(/* webpackChunkName: "bannerAdmin" */ './components/Admin/BannerAdmin.vue')
const LogoAdmin =() =>import(/* webpackChunkName: "admin" */ './components/Admin/LogoAdmin.vue')
const CompanyAdmin =() =>import(/* webpackChunkName: "admin" */ './components/Admin/CompanyAdmin.vue')
const GroupAdmin =() =>import(/* webpackChunkName: "admin" */ './components/Admin/GroupAdmin.vue')
const UserAdmin =() =>import(/* webpackChunkName: "admin" */ './components/Admin/UserAdmin.vue')
const AdminList =() =>import(/* webpackChunkName: "admin" */ './components/Admin/AdminList.vue')
const WhatsNewAdmin  =() =>import(/* webpackChunkName: "footer" */ './components/Admin/WhatsNewAdmin.vue')
const ImportData =() =>import(/* webpackChunkName: "importData" */ './components/Admin/ImportData.vue')
const ImportWizard =() =>import(/* webpackChunkName: "importWizard" */ './components/Admin/ImportWizard.vue')

const About  =() =>import(/* webpackChunkName: "footer" */ './components/Footer/About.vue')
const Contact  =() =>import(/* webpackChunkName: "footer" */ './components/Footer/Contact.vue')
const Legal  =() =>import(/* webpackChunkName: "footer" */ './components/Footer/Legal.vue')
const TermsOfUse  =() =>import(/* webpackChunkName: "footer" */ './components/Footer/TermsOfUse.vue')
const WhatsNew  =() =>import(/* webpackChunkName: "footer" */ './components/Shared/WhatsNew.vue')
const ErrorPage  =() =>import(/* webpackChunkName: "errorPage" */ './components/ErrorPage.vue')

import {auth} from './components/Security/auth'
function requireAuth (to, from, next) {
  if (!auth.isLoggedIn()) {
	console.log('not logged in');
	var currUrl =window.location.pathname.replace(/^(\/v2)/,"");
	if (currUrl.indexOf("login") !==-1){
		return;
	}
    next({
		path: '/login',
		query: {
			redirectTo: currUrl,
		}
		});
	}
	else {
    next()
  }
}

const generateRoutes = () => {

      return [
		{
			path: '/', component: DefaultTenant,
			children: [
				{ name: 'login', path: '/login/:redirectTo?', component: Login, props: true},
				{ name: 'home', path: '', component: Dashboard, beforeEnter: requireAuth },
				{ name: 'favPortfolios', path: '/favPortfolios', component: Dashboard, props: (route) => ({ favType: 'portfolio' }) },
				{ name: 'favReports', path: '/favReports', component: Dashboard, props: (route) => ({ favType: 'report' }) },
				{ name: 'performance', path: '/performance/:id?', component: ReportMaster, props: (route) => ({	reportType: 'performance'	}), beforeEnter: requireAuth },
				{ name: 'risk', path: '/risk/:id?', component: ReportMaster, props: (route) => ({	reportType: 'volatility'	}), beforeEnter: requireAuth },
				{ name: 'correlation', path: '/correlation/:id?', component: ReportMaster, props: (route) => ({	reportType: 'correlation'	}), beforeEnter: requireAuth },
				{ name: 'splits', path: '/splits/:id?', component: ReportMaster, props: (route) => ({	reportType: 'split'	}), beforeEnter: requireAuth },
				{ name: 'pricesReport', path: '/pricesReport/:id?', component: ReportMaster, props: (route) => ({	reportType: 'price'	}), beforeEnter: requireAuth },
				{ name: 'factsheet', path: '/factsheet/:id?', component: FundFactsheet, beforeEnter: requireAuth },
				{ name: 'realPortfolio',	path: '/realPortfolio/:id?', component: RealPortfolio, beforeEnter: requireAuth },
				{ name: 'modelPortfolio', path: '/modelPortfolio/:id?', component: ModelPortfolio, beforeEnter: requireAuth },
				{ name: 'modelPortfolioOptimisation', path: '/modelPortfolioOptimisation/:id?', component: ModelPortfolioOptimisation, beforeEnter: requireAuth },
				{ name: 'rpFactsheet', path: '/rpFactsheet/:id?', component: RPFactSheet, beforeEnter: requireAuth },
				{ name: 'rpReport', path: '/rpReport/:id?', component: RPReport, beforeEnter: requireAuth },
				{ name: 'settings', path: '/settings/:id?', component: UserSettings, beforeEnter: requireAuth },
				{ name: 'importData', path: '/importadata/:id?', component: ImportData, beforeEnter: requireAuth },
				{ name: 'importWizard', path: '/importawizard/', component: ImportWizard, beforeEnter: requireAuth },
				{ name: 'logoAdmin',	path: '/logoAdmin/:id?', component: LogoAdmin, beforeEnter: requireAuth },
				{ name: 'bannerAdmin', path: '/bannerAdmin/:id?', component: BannerAdmin, beforeEnter: requireAuth },
				{ name: 'companyAdmin', path: '/companyAdmin/:id?', component: CompanyAdmin, beforeEnter: requireAuth, props: true  },
				{ name: 'groupAdmin', path: '/groupAdmin/:id?', component: GroupAdmin, beforeEnter: requireAuth, props: true },
				{ name: 'userAdmin', path: '/userAdmin/:id?', component: UserAdmin, beforeEnter: requireAuth, props: true  },
				{ name: 'companyAdminList', path: '/companyAdminList', component: AdminList, props: (route) => ({ adminType: route.params.adminType || 3, parentItem:  route.params.parentItem || null }), beforeEnter: requireAuth },
				{ name: 'groupAdminList', path: '/groupAdminList', component: AdminList, props: (route) => ({ adminType: route.params.adminType || 2, parentItem:  route.params.parentItem || null }), beforeEnter: requireAuth },
				{ name: 'userAdminList', path: '/userAdminList', component: AdminList, props: (route) => ({ adminType: route.params.adminType || 1, parentItem:  route.params.parentItem || null }), beforeEnter: requireAuth },
				{ name: 'about', path: '/about', component: About },
				{ name: 'contact', path: '/contact', component: Contact },
				{ name: 'legal', path: '/legal', component: Legal },
				{ name: 'tsandcs', path: '/tsandcs', component: TermsOfUse },
				{ name: 'whatsNew', path: '/whatsNew', component: WhatsNew },
				{ name: 'whatsNewAdmin', path: '/whatsNewAdmin', component: WhatsNewAdmin, beforeEnter: requireAuth },
				{ name: 'Interal Error', path: '/error-500',  component:ErrorPage, props: { errorMessage: '500 - Interal Error' }, beforeEnter: requireAuth},
				{ path: '*', component:ErrorPage, props: { errorMessage: '404 - Page not found' }, beforeEnter: requireAuth}
			]
		}
      ];

  }

export const routes = generateRoutes();
