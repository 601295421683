<style>

.footer-items{
	justify-content: center;
}

</style>

<template>
	<footer v-if="showFooter" class="footer py-2 d-print-none" :style=" hide? 'height: '+heightOfHide+'px; overflow: hidden;' : ''">
		<div style="position: absolute; margin-top: -8px; z-index: 100;">
			<button :title="hide ? 'hide footer' : 'show footer'" class="btn btn-sm btn-success" @click="toggleFooter">
				<font-awesome-icon :icon="hide ?'chevron-up' : 'chevron-down'" />
			</button>
		</div>
		<div class="container-fluid" style="margin-bottom: 10px;">
			<div class="row offset-md-3 footer-items">
				<div class="col-6 col-md-2">
					<div class="h4">{{ about }}</div>
					<ul>
						<li>
							<router-link :to="{name: 'about'}" tag="a" exact>
								{{ about }}
							</router-link>
						</li>
					</ul>
				</div>
				<div class="col-6 col-md-2">
					<div class="h4">Support</div>
					<ul class="mb-2">
						<li>
							<router-link :to="{name: 'contact'}" tag="a" exact>
								{{ contact }}
							</router-link>
						</li>
						<li>
							<a href="mailto:support@longboatanalytics.com?Subject=Fund%20Focus%20feedback" target="_top">{{ feedback }}</a>
						</li>
					</ul>
				</div>
				<div class="col-6 col-md-3">
					<div class="h4">{{ legal }}</div>
					<ul class="mb-2">
						<li>
							<router-link :to="{name: 'legal'}" tag="a" exact>
								{{ legal }}
							</router-link>
						</li>
						<li>
							<router-link :to="{name: 'tsandcs'}" tag="a" exact>
								{{ terms }}
							</router-link>
						</li>
						<li>
							<a href="https://www.compliancesolutionsstrategies.com/privacy-notice/" target="_blank" rel="noopener">
								{{ privacy }}
							</a>
						</li>
					</ul>
				</div>
				<div class="col-6 col-md-4 mt-2 mt-md-4">
					<a href="https://www.compliancesolutionsstrategies.com/products/longboat-analytics/" rel="noopener"><img :src="getImgUrl('longboat_logo_mm_landscape.png')" alt="logo" class="logo_footer"></a>
				</div>
			</div>
			<div class="row">
				<div id="footer-copyright" class="col-12" style="text-align:center; font-size:10px;">
					Copyright {{ new Date().getFullYear() }} © <a style="font-size:10px;" target="_blank" href="http://www.compliancesolutionsstrategies.com/" rel="noopener">Compliance Solutions Strategies</a> - V.1.x - Latest Database Update {{ latestPriceDate }}
				</div>
			</div>
		</div> <!-- /container -->
	</footer>
</template>

<script>
  import {utils} from '../lib/utils'
  import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
library.add( faChevronDown, faChevronUp  )
  export default {
	name: 'App',
	components: {
		FontAwesomeIcon: FontAwesomeIcon
    },
	data() {
        return {
			latestPriceDate: null,
			hide: false,
			heightOfHide: 32
        }
	},
	computed: {
		showFooter: function()
		{
			if (Object.keys(this.$route.query).indexOf('print') !=-1){
				return false;
			}
			return true;
		},
		isLogin: function (){
			return (this.$route.name ==='login');
		},
		unAuthenticatedLinks: function (){
			return (['about','contact','legal','tsandcs'].indexOf(this.$route.name) >-1);
		},
		about: function() {
			return utils.areStoresSet ? utils.translations.titles.about : "About Us";
		},
		feedback: function() {
			return utils.areStoresSet ? utils.translations.titles.feedback : "Feedback";
		},
		terms: function() {
			return utils.areStoresSet ? utils.translations.titles.terms : "Terms of Use";
		},
		contact: function() {
			return utils.areStoresSet ? utils.translations.titles.contact : "Contact";
		},
		legal: function() {
			return utils.areStoresSet ? utils.translations.titles.legal : "Legal";
		},
		privacy: function() {
			return utils.areStoresSet ? utils.translations.titles.privacy : "Privacy Policy";
		},
	},
	watch: {
		isLogin(){
			this.initData();
		}
	},
	mounted() {
		this.initData();
		this.checkFooterCookie();

    },
	methods:
	{
		getImgUrl(pic) {
			return require('../../assets/'+pic);
		},
		initData() {
			if (!this.isLogin && !this.unAuthenticatedLinks) {
				utils.getLatestDBDate(date =>{
					this.latestPriceDate =date;
				});
			}
		},
		toggleFooter(){
			this.hide =!this.hide;
			this.setFooterCookie(this.hide);
			this.$emit('toggle-footer', this.hide);
		},
		checkFooterCookie()
		{
			if (localStorage.getItem('isFooterHidden')) {
				var cookieValue = JSON.parse(localStorage.getItem('isFooterHidden'));
				if(cookieValue != this.hide){
					this.toggleFooter();
				}
			}
			else
			{
				this.setFooterCookie(false);
			}
		},
		setFooterCookie(isHidden)
		{
			localStorage.setItem('isFooterHidden', isHidden);
		}

	},

  }
</script>

