<template>
	<div id="app">
		<b-modal v-if="showSessionWarning && translations" v-model="showSessionWarning" :hideHeaderClose="true" :title="translations.sessionPrompt.warningTitle" :ok-title="translations.sessionPrompt.continueSession" :cancel-title="translations.sessionPrompt.quitSession" header-class="sectionHeader rounded-top" body-class="modalBody" ok-variant="success" cancel-variant="danger" @ok="refreshSession" @cancel="logOff" @hide="controlHide">
			<div class="row">
				<div class="col-12 text-left">
					<h6>
						{{ sessionWarningMessage }}
					</h6>
				</div>
			</div>
		</b-modal>
		<b-modal v-if="showSplash && translations" v-model="showSplash" :ok-title="translations.UserNotifications.ok" header-class="sectionHeader rounded-top" body-class="modalBody" ok-variant="success" :ok-only="true">
			<div class="row mx-1">
				<div class="col-12 text-left">
					<p style="font-size: 23px; text-align: initial; margin-top: 14px; margin-bottom: 23px;">
						Welcome to Fund Focus
					</p>
					<hr>
					<br>
					<div class="col-xs-12">
						<div class="row d-none d-lg-block">
							<div class="col-xs-12">
								<p style="font-size: 20px;">
									Thank you to the following companies for supporting Fund Focus in the Financial Broker market:
								</p>
								<img :src="rootURL +'/Content/Media/logos/patrons1.PNG'" style="max-width:100%" alt="logo">
							</div>
						</div>
						<div class="row d-none d-lg-block">
							<div class="col-xs-12">
								<img :src="rootURL +'/Content/Media/logos/patrons2.PNG'" style="max-width:100%" alt="logo">
							</div>
						</div>
						<div class="row d-block d-lg-none">
							<div class="col-xs-12">
								<p style="font-size: 16px;">
									Thank you to the following companies for supporting Fund Focus in the Financial Broker market:
								</p>
								<img :src="rootURL +'/Content/Media/logos/patrons1.PNG'" style="max-width:100%" alt="logo">
							</div>
						</div>
						<div class="row d-block d-lg-none">
							<div class="col-xs-12">
								<img :src="rootURL +'/Content/Media/logos/patrons3.PNG'" style="max-width:100%" alt="logo">
							</div>
						</div>
						<div class="row d-block d-lg-none">
							<div class="col-xs-12 text-center">
								<img :src="rootURL +'/Content/Media/logos/Zurich.PNG'" style="max-width:40%" alt="logo">
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
		<router-view />
	</div>
</template>

<script>
import {utils} from './components/lib/utils'
import {auth} from './components/Security/auth'
import { BModal } from 'bootstrap-vue' //ToDo: replace modals with: https://stackoverflow.com/questions/42890385/open-bootstrap-modal-with-vue-js-2-0
import axios from 'axios'
  export default {
    name: 'App',
	components:
	{
		BModal
	},
	computed: {
		translations: function() {
			return utils.translations;
		},
		showSessionWarning:
		{
			get: function() {
				return auth.showSessionWarning;
			},
			set: function(newValue) {
				if (newValue ===true){
					auth.notify();
				}
			}
		},
		sessionWarningMessage: function() {
			return auth.sessionWarningMessage;
		},
		sessionTimeLeft: function(){
			return auth.sessionSecondsLeft;
		},
		rootURL: function() {
			var root =API_BASE_URL;
			return root.replace('/api','');
		},
		showSplash: {
			get: function() {				
				if (!utils.sessionData){
					return false;
				}
				if (utils.sessionData.Site.Id !==1){ //Ireland
					return false;
				}
				if (!utils.sessionData.AllowSplash){
					return false;
				}				
				return (!utils.confirmedSplash);
			},
			set: function(newValue) {
				if (newValue ===false){
					utils.confirmedSplash =true;
				}
			}

		}
	},
	watch: {
		sessionTimeLeft: function(newValue) {
			if (newValue <=0){
				this.logOff();
			}
		}
	},
	beforeDestroy() {
  		auth.stopTimer();
	},
	methods: {
		refreshSession(){
			auth.refreshSession();
		},
		logOff(){
			var currUrl =window.location.pathname.replace(/^(\/v2)/,"");
			if (currUrl.indexOf("login") !==-1){
				currUrl ="";
			}
			auth.logout(resp => {
				 this.$router.push({name: 'login', params: { redirectTo: currUrl }}).catch(err => {}); }
			);
		},
		controlHide(evt){
			evt.preventDefault();		
		}
	}
  }
</script>
