var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container my-5" }, [
    _c(
      "div",
      { staticClass: "my-5 px-4 py-4 rounded", attrs: { id: "loginDiv" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.login($event)
              }
            }
          },
          [
            _c("div", { staticClass: "mt-3 text-center" }, [
              _vm._v("\n\t\t\t\tSign in using your registered account:\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("notification"),
            _vm._v(" "),
            _c("div", { staticClass: "mb-1" }, [
              _c(
                "label",
                { staticClass: "screenreader", attrs: { for: "email" } },
                [_vm._v("Email:")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "rounded px-2",
                attrs: { id: "email", name: "email", placeholder: "email" },
                domProps: { value: _vm.email },
                on: {
                  change: _vm.checkEmail,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-1" }, [
              _c(
                "label",
                { staticClass: "screenreader", attrs: { for: "password" } },
                [_vm._v("Password:")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pass,
                    expression: "pass"
                  }
                ],
                staticClass: "rounded px-2",
                attrs: {
                  id: "password",
                  name: "password",
                  placeholder: "password",
                  type: "password"
                },
                domProps: { value: _vm.pass },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.pass = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "mt-3 text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  class: { hideItem: !_vm.showLink },
                  staticStyle: { color: "white" },
                  attrs: { type: "button" },
                  on: { click: _vm.requestResetMail }
                },
                [_vm._v("\n\t\t\t\t\tforgot password?\n\t\t\t\t")]
              )
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm.error
              ? _c("p", { staticClass: "error" }, [
                  _vm._v("Bad login information")
                ])
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "text-center mb-4" }, [
      _c("span", { staticClass: "thinTitle" }, [_vm._v("FUND")]),
      _vm._v(" "),
      _c("span", { staticClass: "thickTitle" }, [_vm._v("FOCUS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "button btn-sm btn btn-success mt-2",
          attrs: { type: "submit" }
        },
        [_vm._v("Log in")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3 text-center" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-link",
          staticStyle: { color: "white" },
          attrs: { href: "/Account/Login" }
        },
        [
          _vm._v(
            "\n\t\t\t\t\tLogin to previous version of Fund Focus\n\t\t\t\t"
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "underCredentials text-center" }, [
      _c("hr"),
      _vm._v("\n\t\tINVESTMENT DATA ANALYTICS\n\t")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }