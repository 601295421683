var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showSessionWarning && _vm.translations
        ? _c(
            "b-modal",
            {
              attrs: {
                hideHeaderClose: true,
                title: _vm.translations.sessionPrompt.warningTitle,
                "ok-title": _vm.translations.sessionPrompt.continueSession,
                "cancel-title": _vm.translations.sessionPrompt.quitSession,
                "header-class": "sectionHeader rounded-top",
                "body-class": "modalBody",
                "ok-variant": "success",
                "cancel-variant": "danger"
              },
              on: {
                ok: _vm.refreshSession,
                cancel: _vm.logOff,
                hide: _vm.controlHide
              },
              model: {
                value: _vm.showSessionWarning,
                callback: function($$v) {
                  _vm.showSessionWarning = $$v
                },
                expression: "showSessionWarning"
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 text-left" }, [
                  _c("h6", [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.sessionWarningMessage) +
                        "\n\t\t\t\t"
                    )
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSplash && _vm.translations
        ? _c(
            "b-modal",
            {
              attrs: {
                "ok-title": _vm.translations.UserNotifications.ok,
                "header-class": "sectionHeader rounded-top",
                "body-class": "modalBody",
                "ok-variant": "success",
                "ok-only": true
              },
              model: {
                value: _vm.showSplash,
                callback: function($$v) {
                  _vm.showSplash = $$v
                },
                expression: "showSplash"
              }
            },
            [
              _c("div", { staticClass: "row mx-1" }, [
                _c("div", { staticClass: "col-12 text-left" }, [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "23px",
                        "text-align": "initial",
                        "margin-top": "14px",
                        "margin-bottom": "23px"
                      }
                    },
                    [_vm._v("\n\t\t\t\t\tWelcome to Fund Focus\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xs-12" }, [
                    _c("div", { staticClass: "row d-none d-lg-block" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _c("p", { staticStyle: { "font-size": "20px" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tThank you to the following companies for supporting Fund Focus in the Financial Broker market:\n\t\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: {
                            src:
                              _vm.rootURL + "/Content/Media/logos/patrons1.PNG",
                            alt: "logo"
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row d-none d-lg-block" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: {
                            src:
                              _vm.rootURL + "/Content/Media/logos/patrons2.PNG",
                            alt: "logo"
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row d-block d-lg-none" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _c("p", { staticStyle: { "font-size": "16px" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tThank you to the following companies for supporting Fund Focus in the Financial Broker market:\n\t\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: {
                            src:
                              _vm.rootURL + "/Content/Media/logos/patrons1.PNG",
                            alt: "logo"
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row d-block d-lg-none" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: {
                            src:
                              _vm.rootURL + "/Content/Media/logos/patrons3.PNG",
                            alt: "logo"
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row d-block d-lg-none" }, [
                      _c("div", { staticClass: "col-xs-12 text-center" }, [
                        _c("img", {
                          staticStyle: { "max-width": "40%" },
                          attrs: {
                            src:
                              _vm.rootURL + "/Content/Media/logos/Zurich.PNG",
                            alt: "logo"
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }