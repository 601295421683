import 'es6-promise/auto';
import './Common.css'
import Vue from 'vue'
Vue.config.productionTip = false

/*bootstrap config*/
 import 'bootstrap/dist/css/bootstrap.css'
 import 'bootstrap-vue/dist/bootstrap-vue.css'

/* Web Notifications */
import VueNativeNotification from 'vue-native-notification'

Vue.use(VueNativeNotification, {
  // Automatic permission request before showing notification (default: true)
  requestOnNotify: true
})

/*Routing and security*/
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import {routes} from './routes'
const router = new VueRouter({
  mode: 'history',
  routes
});

// /*axios config*/
import {auth} from './components/Security/auth'
import axios from 'axios';


const axiosConfig = {
	baseURL: API_BASE_URL,
	timeout: 2000000,
  withCredentials: true,
  maxRedirects: 0
};



Vue.prototype.$axiosAPI = axios.create(axiosConfig);
Vue.prototype.$axiosAPI.interceptors.response.use(response => {
    auth.resetTimeLeft();
    return response;
  }, error => {
    if (error.response !==undefined && error.response.status !=undefined && error.response.status == 401) {

        var currUrl =window.location.pathname.replace(/^(\/v2)/,"");
        if (currUrl.indexOf("login") !==-1){
          currUrl ="/";
        }
        console.log('auth failed, possible session time out', error.config.url, currUrl);
        auth.logout(resp => {
           router.push({name: 'login', params: { redirectTo: currUrl }}).catch(err => {});
          });
    }
    return Promise.reject(error);
  });

/*"Filter" setup - auto formatting for Data Tables*/
Vue.filter('allFormats', function (value, filter) {

  var months = [
    "01", "02", "03",
    "04", "05", "06", "07",
    "08", "09", "10",
    "11", "12"
  ];
  
  var date;
  var day;
  var monthIndex;
  var year;

  if (filter ==='percent'){
    if (value !==undefined && value !==null && !isNaN(value)) {
      return parseFloat(Math.round(value * 100) / 100).toFixed(2) +'%';
    }else {
      return '--'
    }
  }
  else if (filter ==='price') {
    if (value !==undefined && value !==null) {
      var roundedAmount =parseFloat(Math.round(value * 100) / 100).toFixed(2);
      return '€'+ roundedAmount.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    }else {
      return '--'
    }
  }
  else if (filter ==='date') {
    
    
    if (value !==undefined && value !==null) {
      date = new Date(value);
      day = ("0"+date.getDate()).slice(-2);
			monthIndex = date.getMonth();
			year = date.getFullYear();
  		return day + '/' + months[monthIndex] + '/' + year;
    }else {
      return '--'
    }
  }
  else if (filter ==='numDate') {
    if (value !==undefined && value !==null) {
      date = new Date(value.substring(0, 4)+'-'+ value.substring(4, 6)+'-'+ value.substring(6, 8));
      day = ("0"+date.getDate()).slice(-2);
			monthIndex = date.getMonth();
			year = date.getFullYear();
  		return day + '/' + months[monthIndex] + '/' + year;
    }else {
      return '--'
    }
  }
  else if (filter ==='longdate') {
    if (value !==undefined && value !==null) {
      date = new Date(value);
      var monthNames = [
				"January", "February", "March",
				"April", "May", "June", "July",
				"August", "September", "October",
				"November", "December"
			];

			day = ("0"+date.getDate()).slice(-2);
			monthIndex = date.getMonth();
			year = date.getFullYear();
  		return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }else {
      return '--'
    }
  }
  else {
    if (value !==undefined && value !==null && value !=='') {
      return value;
    }else {
      return '--'
    }
  }
})

import App from './App.vue'
const myApp = new Vue({
  el: '#app',
  router,
  render: h => h(App)

})
