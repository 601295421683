var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFooter
    ? _c(
        "footer",
        {
          staticClass: "footer py-2 d-print-none",
          style: _vm.hide
            ? "height: " + _vm.heightOfHide + "px; overflow: hidden;"
            : ""
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                "margin-top": "-8px",
                "z-index": "100"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success",
                  attrs: { title: _vm.hide ? "hide footer" : "show footer" },
                  on: { click: _vm.toggleFooter }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: _vm.hide ? "chevron-up" : "chevron-down" }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container-fluid",
              staticStyle: { "margin-bottom": "10px" }
            },
            [
              _c("div", { staticClass: "row offset-md-3 footer-items" }, [
                _c("div", { staticClass: "col-6 col-md-2" }, [
                  _c("div", { staticClass: "h4" }, [_vm._v(_vm._s(_vm.about))]),
                  _vm._v(" "),
                  _c("ul", [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "about" },
                              tag: "a",
                              exact: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.about) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 col-md-2" }, [
                  _c("div", { staticClass: "h4" }, [_vm._v("Support")]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "mb-2" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "contact" },
                              tag: "a",
                              exact: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.contact) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "mailto:support@longboatanalytics.com?Subject=Fund%20Focus%20feedback",
                            target: "_top"
                          }
                        },
                        [_vm._v(_vm._s(_vm.feedback))]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 col-md-3" }, [
                  _c("div", { staticClass: "h4" }, [_vm._v(_vm._s(_vm.legal))]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "mb-2" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "legal" },
                              tag: "a",
                              exact: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.legal) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "tsandcs" },
                              tag: "a",
                              exact: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.terms) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://www.compliancesolutionsstrategies.com/privacy-notice/",
                            target: "_blank",
                            rel: "noopener"
                          }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.privacy) +
                              "\n\t\t\t\t\t\t"
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 col-md-4 mt-2 mt-md-4" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://www.compliancesolutionsstrategies.com/products/longboat-analytics/",
                        rel: "noopener"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "logo_footer",
                        attrs: {
                          src: _vm.getImgUrl("longboat_logo_mm_landscape.png"),
                          alt: "logo"
                        }
                      })
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-12",
                    staticStyle: {
                      "text-align": "center",
                      "font-size": "10px"
                    },
                    attrs: { id: "footer-copyright" }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\tCopyright " +
                        _vm._s(new Date().getFullYear()) +
                        " © "
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { "font-size": "10px" },
                        attrs: {
                          target: "_blank",
                          href: "http://www.compliancesolutionsstrategies.com/",
                          rel: "noopener"
                        }
                      },
                      [_vm._v("Compliance Solutions Strategies")]
                    ),
                    _vm._v(
                      " - V.1.x - Latest Database Update " +
                        _vm._s(_vm.latestPriceDate) +
                        "\n\t\t\t"
                    )
                  ]
                )
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }