<style scoped>

.hideItem{
	display: none;
}

</style>

<template>
	<div class="container my-5">
		<div id="loginDiv" class="my-5 px-4 py-4 rounded">
			<h3 class="text-center mb-4">
				<span class="thinTitle">FUND</span> <span class="thickTitle">FOCUS</span>
			</h3>
			<form @submit.prevent="login">
				<div class="mt-3 text-center">
					Sign in using your registered account:
				</div>
				<notification />
				<div class="mb-1">
					<label class="screenreader" for="email">Email:</label><input id="email" v-model="email" name="email" placeholder="email" class="rounded px-2" @change="checkEmail">
				</div>
				<div class="mb-1">
					<label class="screenreader" for="password">Password:</label><input id="password" v-model="pass" name="password" placeholder="password" type="password" class="rounded px-2">
				</div>
				<div>
					<button class="button btn-sm btn btn-success mt-2" type="submit">Log in</button>
				</div>
				<div class="mt-3 text-center">
					<button :class="{'hideItem': !showLink }" type="button" class="btn btn-link" style="color: white;" @click="requestResetMail">
						forgot password?
					</button>
				</div>
				<div class="mt-3 text-center">
					<a href="/Account/Login" class="btn btn-link" style="color: white;">
						Login to previous version of Fund Focus
					</a>
				</div>
				<p v-if="error" class="error">Bad login information</p>
			</form>
		</div>
		<div class="underCredentials text-center">
			<hr>
			INVESTMENT DATA ANALYTICS
		</div>
	</div>
</template>

<script>
import {auth} from './auth'
import {utils} from '../lib/utils' //Translations!
export default {
	components:{
		Notification: () => import(/* webpackChunkName: "toast" */"../Shared/Prompts/Notification.vue"),
	},
	props: {
		redirectTo: { default: '/', type: String }
	},
	data () {
		return {
			email: '',
			pass: '',
			error: false,
			showLink: false,
			timeout: null
		}
	},
	computed: {
		redirectURL: function(){
			var tmp =this.$route.query.redirectTo; //because of next in routes.js requireAuth
			if (this.redirectTo !=='/'){
				tmp =this.redirectTo;
			}
			if (!tmp){
				tmp ='/';
			}
			return tmp;
		}
	},
	mounted() {
		window.mmItalyLogin =this.mmItalyLogin;
	},
	methods: {
		login () {
			let errorTranslations = (window.navigator.language.indexOf("it") >-1) ? 'Errore!' : 'Error!';
			var that =this;
			auth.login(this.email, this.pass, resp => {
				if (resp.authenticated ===true){
					if (this.redirectTo){
						this.$router.push(this.redirectURL).catch(err => {});
					}
				}
				else {
					utils.messagePrompt.customMessage ='<strong>' + errorTranslations + '</strong>&nbsp; '+ resp.message;
					utils.messagePrompt.error =true;
				}

			});
		},
		mmItalyLogin(email, password){
			auth.login(email, password, resp => {
				if (resp.authenticated ===true){
					if (this.redirectTo){
						this.$router.push(this.redirectURL).catch(err => {});
					}
				}
				else {
					utils.messagePrompt.customMessage ='<strong>Errore!</strong>&nbsp; '+ resp.message;
					utils.messagePrompt.error =true;
				}

			});
		},
		checkEmail(){

			clearTimeout(this.timeout);
			var that =this;
			this.timeout = setTimeout(function () {
			 	var pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
				if (pattern.test(that.email)) {
					auth.checkEmail(that.email, resp => {
						if (resp !=='False'){
							that.showLink =true;
						}
						else {
							that.showLink =false;
						}
					});
				}
				else {
					that.showLink =false;
				}
			 }, 500);
		},
		requestResetMail() {
			auth.requestPasswordEmail(this.email,false,sent => {
				if (sent){
					utils.messagePrompt.customMessage ='<strong>Success!</strong>&nbsp;An email with a link to reset your password has been sent to you. Please, access your email inbox to reset your password. The link sent is valid for <strong>24 hours</strong>.';
					utils.messagePrompt.success =true;
				}
				else {
					utils.messagePrompt.customMessage ='<strong>Error!</strong>&nbsp; Something wrong has happened. Please, click <a href="mailto:support@longboatanalytics.com?Subject=Fund%20Focus%20reset%20password" style="text-decoration:underline;color:#B94A48"> here </a> to contact our support team.';
					utils.messagePrompt.error =true;
				}
			});
		}
	}
}
</script>
<style>
.error {
  color: red;
}
</style>