var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { loginContent: _vm.isLogin }, attrs: { id: "tenant" } },
    [
      _c("app-header"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "my-2",
          class: _vm.expand ? "contentWrapperExpanded" : "contentWrapper",
          attrs: { id: "contentWrapper" }
        },
        [
          _vm.showBanner && !_vm.isLogin ? _c("app-banner") : _vm._e(),
          _vm._v(" "),
          _c("main", [
            _c(
              "div",
              { staticClass: "container-fluid", attrs: { id: "content" } },
              [_c("router-view")],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCookiePrompt
        ? _c(
            "div",
            {
              style: _vm.showCookiePrompt ? "display:block;" : "",
              attrs: { id: "cookiepolicy", role: "dialog" }
            },
            [
              _c("div", { staticClass: "msg-cookies" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { attrs: { id: "cookieAcceptDiv" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-small btn-danger",
                      staticStyle: { cursor: "pointer" },
                      attrs: { id: "cookieaccept" },
                      on: { click: _vm.cookiesAccepted }
                    },
                    [_vm._v("ACCEPT")]
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("app-footer", { on: { "toggle-footer": _vm.expandContentWrapper } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "cookieText" } }, [
      _vm._v(
        "\n\t\t\t\tOur website uses cookies to enhance your browsing experience. For more information on cookies please view our\n\t\t\t\t"
      ),
      _c(
        "a",
        {
          staticClass: "pp-cookies-link ml-1",
          attrs: {
            href:
              "https://www.compliancesolutionsstrategies.com/privacy-notice/",
            target: "_blank",
            rel: "noopener"
          }
        },
        [_vm._v("privacy policy")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("table", { staticClass: "table table-responsive table-sm" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Type")]),
            _vm._v(" "),
            _c("th", [_vm._v("Name")]),
            _vm._v(" "),
            _c("th", [_vm._v("Provider")]),
            _vm._v(" "),
            _c("th", [_vm._v("Purpose")]),
            _vm._v(" "),
            _c("th", [_vm._v("Expiry")])
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("Strictly Necessary")]),
            _vm._v(" "),
            _c("td", [_vm._v(" __RequestVerificationToken")]),
            _vm._v(" "),
            _c("td", [_vm._v("Longboat Analytics")]),
            _vm._v(" "),
            _c("td", [_vm._v("Anti Forgery Token")]),
            _vm._v(" "),
            _c("td", [_vm._v("Session")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Strictly Necessary")]),
            _vm._v(" "),
            _c("td", [_vm._v(" .ASPXAUTH")]),
            _vm._v(" "),
            _c("td", [_vm._v("Longboat Analytics")]),
            _vm._v(" "),
            _c("td", [_vm._v("Used for Authentication")]),
            _vm._v(" "),
            _c("td", [_vm._v("Session")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Strictly Necessary")]),
            _vm._v(" "),
            _c("td", [_vm._v("ASP.NET_SessionId")]),
            _vm._v(" "),
            _c("td", [_vm._v("Longboat Analytics")]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "To enable session state for user; to remember applied filtering"
              )
            ]),
            _vm._v(" "),
            _c("td", [_vm._v("Session")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Strictly Necessary")]),
            _vm._v(" "),
            _c("td", [_vm._v("cookie_policy_accepted")]),
            _vm._v(" "),
            _c("td", [_vm._v("Longboat Analytics")]),
            _vm._v(" "),
            _c("td", [_vm._v("To remember acknowledgement of cookies")]),
            _vm._v(" "),
            _c("td", [_vm._v("365 days")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }