import Vue from 'vue'

export const utils = new Vue({
    data: {
      favourites: null,
      sessionData: null,
      translations: null,
      confirmedSplash: false,
      messagePrompt: {
          success: false,
          error: false,
          warning: false,
          info: false,
          customMessage: null
      },
      enum: {
            reportTypeName: {
                performance: "performance",
                volatility: "volatility",
                correlation: "correlation",
                modelportfolio: "modelportfolio",
                realportfolio: "realportfolio",
                split: "split",
                portfoliooptimization: "portfoliooptimization",
                price: "price"
            },
            reportTypeId: {
                performance: 2,
                volatility: 3,
                modelportfolio: 4,
                realportfolio: 5,
                correlation: 9,
                split: 10,
                portfoliooptimization: 11,
                price: 12
            },
            entityTypeName: {
                Fund: "Fund",
                fund: "Fund",
                Index: "Index",
                index: "Index",
                Currency: "Currency",
                currency: "Currency",
                ModelPortfolioFixed: "ModelPortfolioFixed",
                modelportfoliofixed: "ModelPortfolioFixed",
                ModelPortfolioDrifting: "ModelPortfolioDrifting",
                modelportfoliodrifting: "ModelPortfolioDrifting",
                CategoryAverage: "CategoryAverage",
                categoryaverage: "CategoryAverage",
                RealPortfolio: "RealPortfolio",
                realportfolio: "RealPortfolio",
                MyFunds: "MyFunds",
                MyIndices: "MyIndices",
                Liquidity:"Liquidity",
                liquidity:"Liquidity",
                MyFundsInvestTypeSelected:'MyFunds/0'
            },
            entityTypeId:  {
                Fund: 1,
                fund: 1,
                Index: 2,
                index: 2,
                Currency: 3,
                currency: 3,
                ModelPortfolioDrifting: 4,
                modelportfoliodrifting: 4,
                ModelPortfolioFixed: 5,
                modelportfoliofixed:5,
                CategoryAverage: 6,
                categoryaverage: 6,
                RealPortfolio: 7,
                realportfolio: 7,
                Benchmark: 8,
                benchmark: 8,
                Category: 12,
                Company: 15,
                Supplier: 19,
                MyFunds: 20,
                MyIndices: 21
            },
            selectByEnum:{
                Company: "company",
                Category: "category",
                RealPortfolio: "real_portfolio",
                ModelPortfolio: "model_portfolio",
                Region: "region",
                Supplier: "supplier",
                Currency: "currency",
                Search: "search",
                Index: "index",
                CategoryAverage: "categoryaverage"
            },
            chartTypeId: {
                VerticalColumn: 0,
                HorizontalColumn: 1,
                LineGraph: 2,
                PieChart: 3,
                ScatterChart: 4,
                FundDescription: 5,
                FundDetails: 6,
                PerfTable: 7,
                Top10Table: 8,
                RiskTable: 9,
                DrawGainTable: 10,
                EfficiencyFrontier: 11
            },
            chartSourceId: {
                PerformanceLine : 0,
                PerformanceBarHorizontal: 1,
                MonthlyPerformanceBar : 2,
                YearlyPerformanceBar : 3,
                PerformanceVsRisk: 4,
                PerformanceBarVertical: 5,
                Description: 6,
                Rating: 7,
                PerfTable: 8,
                HighLevelAssetSplit: 9,
                LowLevelAssetSplit: 10,
                GeoSplit: 11,
                Top10Holdings: 12,
                RiskTable: 13,
                FundVsFundsInCategory: 14,
                DrawGainTable: 15,
                RollingIndicator: 16
            },
            optimisationObjective: {
                MaximizeSharpe: 1,
                TargetRisk: 2,
                TargetReturn: 3
            },
            adminTypeId: {
                User: 1,
                Group: 2,
                Company: 3
            }
      }
	},
	computed: {
        getUser(){
            return this.sessionData.User;
        },
        userCompany(){
             return this.sessionData.Group.CompanyId;
         },
        areStoresSet()
        {
            return (this.favourites!==null && this.sessionData!==null && this.translations!==null);
        },
        isCompanyAdmin()
        {
            return this.areStoresSet && this.sessionData.User.Type ==1;
        },
        isAdminUser()
        {
            return this.areStoresSet &&  this.sessionData.User.Type ==2;
        },
        isVendorAdmin()
        {
            return this.areStoresSet &&  this.sessionData.User.Type ==3;
        },
        userCanEditLogo()
        {
            return this.areStoresSet && this.sessionData.User.AllowEditLogo;
        },
        performanceEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.Performance;
            }
            return false;
        },
        riskEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.Volatility;
            }
            return false;
        },
        correlationEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.Correlation;
            }
            return false;
        },
        assetSplitEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.AssetSplit;
            }
            return false;
        },
        pricesReportEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.PricesReport;
            }
            return false;
        },
        exportPricesEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.ExportPrices && this.sessionData.Company.ExportPricesInterval > 0;
            }
            return false;
        },
        modelPortfolioEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.ModelPortfolio;
            }
            return false;
        },
        realPortfolioEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.RealPortfolio;
            }
            return false;
        },
        portfolioOptimisationEnabled()
        {
            if (this.areStoresSet){
                return this.sessionData.Group.PortfolioOptimisation;
            }
            return false;
        },
        companyLogo()
        {
            if (this.areStoresSet){
                if(this.sessionData.Settings.CompanyLogo ===true){
                    if(this.sessionData.Company.Logo != null || this.sessionData.Company.Logo != "")
                    {
                        return this.sessionData.Company.Logo;
                    };
                }
            }
            return null;
        },
        loadedChartSettings(){
            return (localStorage.getItem('chartSettings')) ? true : false;
        },
        userRFRData(){
            return {
                RFRPercentage: this.sessionData.Settings.RFRPercentage,
                RFRName: this.sessionData.Settings.RFRName ? this.sessionData.Settings.RFRName : ""
              }
        },
        userCurrRatesProvider(){
            if (this.areStoresSet){
                return this.sessionData.Settings.CurrRatesProvider;
            }
            return 0;
        },
        getSite(){
            return this.sessionData.Site.Name;
        }
    },
	watch:
	{
		favourites(newData)
		{
			const parsed = JSON.stringify(newData);
			localStorage.setItem('favourites', parsed);
		},
		sessionData(newData)
		{
			const parsed = JSON.stringify(newData);
			localStorage.setItem('sessionData', parsed);
		},
		translations(newData)
		{
			const parsed = JSON.stringify(newData);
			localStorage.setItem('translations', parsed);
        },
        confirmedSplash(newData)
		{
			const parsed = JSON.stringify(newData);
			localStorage.setItem('confirmedSplash', parsed);
        }
	},

    created() {
		if (localStorage.getItem('favourites')) {
			try {
				this.favourites =JSON.parse(localStorage.getItem('favourites'));
			} catch(e) {
				localStorage.removeItem('favourites');
			}
		}
		if (localStorage.getItem('sessionData')) {
			try {
				this.sessionData =JSON.parse(localStorage.getItem('sessionData'));
			} catch(e) {
				localStorage.removeItem('sessionData');
			}
		}
		if (localStorage.getItem('translations')) {
			try {
				this.translations =JSON.parse(localStorage.getItem('translations'));
			} catch(e) {
				localStorage.removeItem('translations');
			}
        }
        if (localStorage.getItem('confirmedSplash')) {
			try {
				this.confirmedSplash =JSON.parse(localStorage.getItem('confirmedSplash'));
			} catch(e) {
				localStorage.removeItem('confirmedSplash');
			}
        }
	},
    methods:
    {
        IsMobile(){
            var isMobile = false; //initiate as false
            // device detection
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
                isMobile = true;
            }
            return isMobile;
        },
        populateSelectedItems(array) {
            localStorage.setItem('selectedItems', JSON.stringify(array));
        },
        getSelectedItems() {
            if (localStorage.getItem('selectedItems')) {
                return JSON.parse(localStorage.getItem('selectedItems'));
            }
            return [];
        },
        populateFavourites(cb) {
            this.$axiosAPI.get('/Favourites/GetPerformances?format=false').then((response)=> {
				this.favourites =response.data;
                if (cb) cb();
            })
            .catch((error)=>{

            });
        },
        populateTranslations(cb) {
            this.$axiosAPI.get('/LocalStrings?getExtended=true').then((response)=> {
				this.translations =response.data;
                if (cb) cb();
            })
            .catch((error)=>{

            });
        },
        updateGroup(newData){
            this.sessionData.Group = newData;
        },
        updateCurrRatesProvider(providerId){
            this.sessionData.Settings.CurrRatesProvider = providerId;
        },
        populateUserDetails(cb) {
            this.$axiosAPI.get('/Session').then((response)=> {
				this.sessionData =response.data;
                if (cb) cb();
            })
            .catch((error)=>{

            });
        },
        getLatestDBDate(cb) {
            if (localStorage.getItem('latestDbDate_refresh')) {
                var now =(new Date());
                var refreshby = new Date(localStorage.getItem('latestDbDate_refresh'));
                if (now <refreshby && localStorage.getItem('latestDbDate')) {
                    try {
                        cb(JSON.parse(localStorage.getItem('latestDbDate')));
                        return;
                    } catch(e) {
                        localStorage.removeItem('latestDbDate');
                    }
                }
            }
            this.$axiosAPI.get('LatestPriceUpdateDate').then((response)=> {
                const parsed = JSON.stringify(response.data);
                localStorage.setItem('latestDbDate', parsed);
                var minutes =5;
                var refreshby = new Date((new Date()).getTime() + minutes*60000);
                localStorage.setItem('latestDbDate_refresh', refreshby);
                cb(response.data);
            })
            .catch((error)=>{

            });
        },
        getReportOptionLists(cb) {
            if ( localStorage.getItem('roLists')) {
                try {

                    cb(JSON.parse(localStorage.getItem('roLists')));
                    return;
                } catch(e) {
                    localStorage.removeItem('roLists');
                }
            }
            this.$axiosAPI.get('ReportOptionLists').then((response)=> {
                const parsed = JSON.stringify(response.data);
                localStorage.setItem('roLists', parsed);
                cb(response.data);
                return response.data;
            }).catch((error)=>{

            });
        },
        getChartSettings(cb) {
            if ( localStorage.getItem('chartSettings')) {
                try {

                    cb(JSON.parse(localStorage.getItem('chartSettings')));
                    return;
                } catch(e) {
                    localStorage.removeItem('chartSettings');
                }
            }
            this.$axiosAPI.post('ChartData/GetChartSettings').then((response)=> {
                const parsed = JSON.stringify(response.data);
                localStorage.setItem('chartSettings', parsed);
                cb(response.data);
                return response.data;
            }).catch((error)=>{

            });
        },
        addChartSetting(postdata, cb) {
            var settings =[];
            if ( localStorage.getItem('chartSettings')) {
                try {
                    settings =JSON.parse(localStorage.getItem('chartSettings'));
                } catch(e) {
                    localStorage.removeItem('chartSettings');
                }
            }
            for (var i= settings.length-1; i>=0; i--){
                if (settings[i].Id ==postdata.Id && settings[i].ChartTypeId ==postdata.ChartTypeId){
                    settings.splice(i, 1);
                }
            }
            settings.push(postdata);
            this.$axiosAPI.post('/ChartData/StoreChartSettings',settings).then((response)=> {
                if (response.data ===true){
                    const parsed = JSON.stringify(settings);
                    localStorage.setItem('chartSettings', parsed);
                }
                cb(settings);
            }).catch((error)=>{

            });
        },
        removeChartSetting(postdata, cb) {
            var settings =[];
            if ( localStorage.getItem('chartSettings')) {
                try {
                    settings =JSON.parse(localStorage.getItem('chartSettings'));
                } catch(e) {
                    localStorage.removeItem('chartSettings');
                }
            }
            for (var i= settings.length-1; i>=0; i--){
                if (settings[i].Id ==postdata.Id && settings[i].ChartTypeId ==postdata.ChartTypeId){
                    settings.splice(i, 1);
                }
            }
            this.$axiosAPI.post('/ChartData/StoreChartSettings',settings).then((response)=> {
                if (response.data ===true){
                    const parsed = JSON.stringify(settings);
                    localStorage.setItem('chartSettings', parsed);
                }
                cb(settings);
            }).catch((error)=>{

            });
        },
        getDefaultReportOptions(cb) {
            if ( localStorage.getItem('roDefaults')) {
                try {
                    cb(JSON.parse(localStorage.getItem('roDefaults')));
                    return;
                } catch(e) {
                    localStorage.removeItem('roDefaults');
                }
            }
            this.$axiosAPI.get('Reportoptions').then((response)=> {
                const parsed = JSON.stringify(response.data);
                localStorage.setItem('roDefaults', parsed);
                cb(response.data);
                return response.data;
            }).catch((error)=>{

            });
        },

        //this is not for retrieving data, just setting.
        setStores(cb) {
            this.populateUserDetails(f => {
                 this.populateTranslations(f => {
                     this.populateFavourites(f => { cb();})
                });
            });
            this.getLatestDBDate(f=>{});
        },
        clearStores(cb) {
            this.sessionData =null;
            this.favourites = null;
            this.translations =null;
            this.confirmedSplash =false;
            localStorage.clear();
            if (cb) cb();
        },
        NewGuid(prefix) {
            var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });

            if (prefix != undefined) {
                return prefix +"_" + guid;
            }

            return guid;
        },
        updateRFRLocally(rFRName,userSettings){

            this.sessionData.Settings.RFRName = rFRName;
            this.sessionData.Settings.RFRIndexCountryId = userSettings.RFRIndexCountryId;
            this.sessionData.Settings.RFRIndexId = userSettings.RFRIndexId;
            this.sessionData.Settings.RFRPercentage = userSettings.RFRPercentage;
        },
        getDaysBetween(from, to){
			var fromSplit = from.split('/');
			var first  =new Date(fromSplit[2], fromSplit[1]-1, fromSplit[0]);
			var toSplit = to.split('/');
			var second  =new Date(toSplit[2], toSplit[1]-1, toSplit[0]);
			return Math.round((second-first)/(1000*60*60*24));
		},
		addDays(date, days, gmtFormat) {
            if (typeof date === 'string')
            {
                date =this.gmtFormatToDate(date);
            }
			var result = new Date(date);
            result.setDate(result.getDate() + days);
            if (gmtFormat) {
                return this.dateToGMTFormat(result);
            }
            else {
                return result;
            }
        },
        addMonths(date, months, gmtFormat) {
            if (typeof date === 'string')
            {
                date =this.gmtFormatToDate(date);
            }
			var result = new Date(date);
            result.setMonth(result.getMonth()+months);
			if (gmtFormat) {
                return this.dateToGMTFormat(result);
            }
            else {
                return result;
            }
        },
        addYears(date, years, gmtFormat) {
            if (typeof date === 'string')
            {
                date =this.gmtFormatToDate(date);
            }
			var result = new Date(date);
            result.setFullYear(result.getFullYear()+years);
			if (gmtFormat) {
                return this.dateToGMTFormat(result);
            }
            else {
                return result;
            }
		},
		numericToGMTFormat(date){
			var year = date.substring(0, 4);
			var month = date.substring(4, 6);
			var day = date.substring(6, 8);
			return day +'/'+month +'/' + year;
		},
		dateToGMTFormat(date){
			var dt = new Date(date);
			var year =dt.getFullYear();
			var month ="0" + (dt.getMonth()+1);
			var day ="0" +dt.getDate();
			var str = day.slice(-2) +"/" + month.slice(-2) +"/" +year;
			return str;
        },
		gmtFormatToDate(str){
			var tmp =str.split('/');
			if (tmp.length !==3) { return; }
			try {
				var year = parseInt(tmp[2]);
				var month = parseInt(tmp[1])-1;
				var day = parseInt(tmp[0]);
				return new Date(Date.UTC(year,month,day));
			} catch(e) {
				console.log("error parsing date");
				return;
			}
        },
        getDateFromNum(value){
			if (value !==undefined && value !==null) {
				var month = parseInt(value.substring(4, 6)) -1;
				var now_utc =Date.UTC(value.substring(0, 4), month, value.substring(6, 8))
				var date = new Date(now_utc);
				return date;
			}
			else {
				return null;
			}
		},
		getNumFromDate(value){
			if (value !==undefined && value !==null) {
				var year =value.getFullYear();
				var month = value.getMonth()+1;
				month = ('0'+month).slice(-2);
				var day = value.getDate();
				var num = year.toString() + month.toString() +('0'+day.toString()).slice(-2);
				return num;
			}
			else {
				return null;
			}
        },
        removeSelectedItems() {
            localStorage.removeItem('selectedItems');
        },
    }
  });
  export const browserSupport = new Vue({
    data: {
      hasStickySupport: false
    },
    methods:
    {
      initialise(){
              this.hasStickySupport = (this.cssPropertyValueSupported('position','sticky') || this.cssPropertyValueSupported('position','-webkit-sticky') );
      },
      cssPropertyValueSupported(prop, value) {
        var d = document.createElement('div');
              d.style[prop] = value;
              return d.style[prop] === value;
      },
    },
  });

