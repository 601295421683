
import axios from 'axios'
import {utils} from '../lib/utils'

import Vue from 'vue'

export const auth = new Vue({
	data: {
		sessionSecondsLeft: (20 *60),
		sessionPromptAfterSeconds: 120,
		sessionTimeSeconds: (20 *60),
		showSessionWarning: false,
		sessionWarningMessage: '',
		activeTimers: [],
	},
	methods:
    {
		accountRoot() {
			var accountURL =API_BASE_URL;
			if (accountURL.slice(-3) ==='api'){
				accountURL = accountURL.substring(0, accountURL.length -3);
			}
			accountURL = accountURL +'Account/';
			return accountURL;
		},
		isLoggedIn () {
			return utils.areStoresSet;
		},
		login (email, pass, cb) {
			if (this.isLoggedIn()) {
				if (cb) cb(true)
				return
			}
			var that = this;
			var accRoot =this.accountRoot();
			loginRequest(accRoot, email, pass, (res) => {
				if (res.authenticated) {
					utils.setStores(
						f => {
							if (!that.isLoggedIn()) {
								if (cb) cb(res);
							}
							else {
								this.setTimer();
								if (cb) cb(res);
							}
						}
					);
				}
				else
				{
					if (cb) cb(res)
				}
			})
		},
		logout (cb) {
			this.stopTimer();
			var accountURL =this.accountRoot() +'LogOff';
			axios.post(accountURL).then(resp =>
				{
					utils.clearStores(f => {
						if (cb) cb();
					} );
				}
			);
		},
		requestPasswordEmail(email,isNew, cb){
			if(email == null){return;}
			var accountURL = this.accountRoot() +'GetResetPasswordToken';
			const params ={
				'email': email,
				'newUser': isNew
		};
			axios.post(accountURL,params).then(resp =>
				{
					if (cb) cb(resp.data);
				}
			);
		},
		checkEmail(email, cb){
			var accountURL =this.accountRoot() +'ShowLinkResetPassword';
			const params ={'email': email};
			axios.post(accountURL,params).then(resp =>
				{
					if (cb) cb(resp.data);
				}
			);
		},
		/* Session timeout related */
		setTimer(){
			this.resetTimeLeft();
			var that =this;
			var warningMessage =utils.translations.sessionPrompt.warningMessage;
			this.cleanTimers();
			var timer = setInterval(function(){
				if (that.sessionSecondsLeft >0){
					that.sessionSecondsLeft =that.sessionSecondsLeft -1;
				}
				that.showSessionWarning = (that.sessionSecondsLeft <=that.sessionPromptAfterSeconds);
				if (that.showSessionWarning){
					that.sessionWarningMessage = warningMessage.replace("[seconds]", that.sessionSecondsLeft);
				}
			}, 1000);
			this.activeTimers.push(timer);
		},
		stopTimer(){
			this.resetTimeLeft();
			this.cleanTimers();
		},
		resetTimeLeft(){
			this.showSessionWarning =false;
			this.sessionSecondsLeft =this.sessionTimeSeconds;
		},
		notify () {
			// https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
			this.$notification.show('Fund Focus', {
				body: utils.translations.sessionPrompt.session_expiring
				,icon: '/favicon.ico'
				,timeout: 4
			}, {})
		}
		,refreshSession(){
			var accountURL =this.accountRoot() +'UpdateSession';
			axios.post(accountURL).then(resp =>
				{
					this.resetTimeLeft();
				}
			);

		},
		cleanTimers(){
			if(this.activeTimers.length > 0){
				for (var i = 0; i < this.activeTimers.length; i++) {
					clearInterval(this.activeTimers[i]);
				  }
				  this.activeTimers = [];
			}
		}
	}
})

function loginRequest(accountRoot, email, pass, cb) {

	var accountURL =accountRoot +'EvergreenLogin';
	const params ={'email': email, 'password': pass};

	axios.post(accountURL,params, { withCredentials: true }).then(resp => {
		cb({authenticated: true, message: null });
	}).
	catch(function (error) {
		console.log(error);
		cb({ authenticated: false, message: error.response.statusText });
	});
}
