<template>
	<div id="tenant" :class="{'loginContent': isLogin}">
		<app-header />
		<div id="contentWrapper" :class="expand ? 'contentWrapperExpanded': 'contentWrapper'" class="my-2">
			<app-banner v-if="showBanner && !isLogin" />
			<main>
				<div id="content" class="container-fluid">
					<router-view />
				</div>
			</main>
		</div>
		<div v-if="showCookiePrompt" id="cookiepolicy" role="dialog" :style="showCookiePrompt ? 'display:block;': ''">
			<div class="msg-cookies">
				<div id="cookieText">
					Our website uses cookies to enhance your browsing experience. For more information on cookies please view our
					<a href="https://www.compliancesolutionsstrategies.com/privacy-notice/" class="pp-cookies-link ml-1" target="_blank" rel="noopener">privacy policy</a>
				</div>
				<div class="col-12">
					<table class="table table-responsive table-sm">
						<thead>
							<tr>
								<th>Type</th>
								<th>Name</th>
								<th>Provider</th>
								<th>Purpose</th>
								<th>Expiry</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Strictly Necessary</td>
								<td> __RequestVerificationToken</td>
								<td>Longboat Analytics</td>
								<td>Anti Forgery Token</td>
								<td>Session</td>
							</tr>
							<tr>
								<td>Strictly Necessary</td>
								<td> .ASPXAUTH</td>
								<td>Longboat Analytics</td>
								<td>Used for Authentication</td>
								<td>Session</td>
							</tr>
							<tr>
								<td>Strictly Necessary</td>
								<td>ASP.NET_SessionId</td>
								<td>Longboat Analytics</td>
								<td>To enable session state for user; to remember applied filtering</td>
								<td>Session</td>
							</tr>
							<tr>
								<td>Strictly Necessary</td>
								<td>cookie_policy_accepted</td>
								<td>Longboat Analytics</td>
								<td>To remember acknowledgement of cookies</td>
								<td>365 days</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div id="cookieAcceptDiv">
					<a id="cookieaccept" class="btn btn-small btn-danger" style="cursor:pointer" @click="cookiesAccepted">ACCEPT</a>
				</div>
			</div>
		</div>
		<app-footer @toggle-footer="expandContentWrapper" />
	</div>
</template>

<script>


  import Footer from '../Footer/Footer.vue'
  import {utils} from '../lib/utils'
  import {browserSupport} from '../lib/utils'
  export default {
    name: 'Tenant',
    components: {
      appHeader: () => import(/* webpackChunkName: "header" */"../Header.vue"),
      appFooter: Footer,
      appBanner:() => import(/* webpackChunkName: "header" */"../BannerHeader.vue")
	},
	data() {
		return {
				showCookiePrompt: true,
				expand: false
			}
		},
	computed: {
		showBanner: function() {
			return utils.sessionData ===null || utils.sessionData.Site.Culture ==="en-IE";
		},
		isLogin: function() {
			return this.$route.name === "login";
		},
		translations: function () {
			return utils.translations;
		},
	},
	mounted() {
		this.checkCookieVisibility();
		browserSupport.initialise();
	},
	methods: {
		cookiesAccepted(){
			document.cookie="cookie_policy_accepted=true";
			this.checkCookieVisibility();
		},
		checkCookieVisibility() {
			var showCookiePrompt = true;
			if (Object.keys(this.$route.query).indexOf('print') !=-1){
				showCookiePrompt =false;
			}
			var x =document.cookie;
			if (x.indexOf('cookie_policy_accepted')>-1) {
				showCookiePrompt =false;
			}
			this.showCookiePrompt = showCookiePrompt;
		},
		expandContentWrapper(expand) {
			this.expand =expand;
		}
	}
  }
</script>